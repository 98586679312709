import { MainLayout, AdminLayout } from "Layouts";
import { lazy } from "react";
import {
  Home,
  // ResultLesson,
  Login,
  HomeAdmin,
  InvalidCourseDependencies,
  CourseImport,
  SchoolGroupImport,
  PageNotAuthen,
	SeihokuData,
	SchoolGroup,
  ValidCourseDependencies,
  TwelveGroup,
  ImportFlow,
  DeleteScheduled,
  ManagerSchedules,
  PageOption,
  Maintenance,
  SettingMaintenance,
  DetailScheduleds
} from "Pages";

const ResultLesson = lazy(()=> import('Pages/ResultLesson'))
const LessonResults = lazy(()=> import('Pages/LessonResults'))
export const navigator = {
  HOME: "/",
  MAINTENANCE: "/maintenance",
  RESULT_LESSON: "/result-lesson/:id/:curriculum",
  LESSON_RESULTS: "/student/:id/curriculum/:curriculum/lesson-results",
  STUDENT_ID: "/student/curriculum/:ID/:curriculum",
  STUDENT_ID_DETAIL: "/student/curriculum-detail/:ID/:curriculum",
  STUDENT_OPTION: "/student/:ID",
  // STUDENT_ID: "/student/:ID",
  // STUDENT_OPTION: "/student/:ID",
  NOT_AUTHEN: "/error",

  // ADMIN
  ADMIN: "/admin",
  ADMIN_COURSE_VALID: "/admin/course_valid",
  ADMIN_LOGIN: "/admin/login",
  ADMIN_COURSE_IMPORT_INVALID: "/admin/course_import_invalid",
  ADMIN_12_GROUP: "/admin/12_group",
  ADMIN_COURSE_DEPENDENCIES_VALID: "/admin/course_dependencies_valid",
  ADMIN_COURSE_DEPENDENCIES_INVALID: "/admin/course_dependencies_invalid",
  ADMIN_SCHOOL_GROUP_IMPORT_INVALID: "/admin/school_group_import_invalid",
  ADMIN_SCHOOL_GROUP_VALID: "/admin/school_group_valid",
  ADMIN_SEIHOKU_IMPORT: "/admin/seihoku_import",
  ADMIN_IMPORT_FLOW: "/admin/import_flow",
  ADMIN_DELETE_SCHEDULED: "/admin/delete_scheduled_courses",
  ADMIN_MANAGER_SCHEDULES: "/admin/manager_schedules",
  ADMIN_MAINTENANCE: "/admin/maintenance",
};
export const ROUTES = [
  {
    path: navigator.MAINTENANCE,
    component: Maintenance,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.HOME,
    component: Home,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.RESULT_LESSON,
    component: ResultLesson,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.LESSON_RESULTS,
    component: LessonResults,
    // layout: LessonLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.STUDENT_ID,
    component: Home,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.STUDENT_ID_DETAIL,
    component: DetailScheduleds,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.NOT_AUTHEN,
    component: PageNotAuthen,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.STUDENT_OPTION,
    component: PageOption,
    layout: MainLayout,
    rules: ["user"],
    exact: true,
  },
  {
    path: navigator.ADMIN_LOGIN,
    component: Login,
    layout: MainLayout,
    rules: ["admin"],
    exact: true,
  },
  {
    path: navigator.ADMIN_COURSE_VALID,
    component: HomeAdmin,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_12_GROUP,
    component: TwelveGroup,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_COURSE_DEPENDENCIES_VALID,
    component: ValidCourseDependencies,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_COURSE_DEPENDENCIES_INVALID,
    component: InvalidCourseDependencies,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_COURSE_IMPORT_INVALID,
    component: CourseImport,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_SCHOOL_GROUP_IMPORT_INVALID,
    component: SchoolGroupImport,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_SCHOOL_GROUP_VALID,
    component: SchoolGroup,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_SEIHOKU_IMPORT,
    component: SeihokuData,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_IMPORT_FLOW,
    component: ImportFlow,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_DELETE_SCHEDULED,
    component: DeleteScheduled,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_MANAGER_SCHEDULES,
    component: ManagerSchedules,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
  {
    path: navigator.ADMIN_MAINTENANCE,
    component: SettingMaintenance,
    layout: AdminLayout,
    rules: ["admin"],
    exact: true,
    isProtected:true,
  },
];

export default navigator;
