import { all, takeLatest } from 'redux-saga/effects'
import { TYPES } from 'Store/Actions'
import sagaHelper from 'Utils/saga-helper'
import { 
  exportScheduleCourse, 
  getScheduleCourse, 
  getCompulsorySubjects, 
  getMaxScore, 
  getStudentInformation, 
  getSubjectContent, 
  getAllCourseName, 
  searchCourseName, 
  getCourseByName, 
  addNewClassSchedule, 
  getCourseTypeName, 
  getCourseSubjects, 
  getInforStudentSaved, 
  getListUniversity, 
  getFullUniversityInformation, 
  getAspirationalId, 
  getCompulsorySubjectsV2, 
  getInformationPrint,
  getListCurriculum ,
  getCurriculumDetail,
  addAiCurriculum,
  updateCalender, 
  activeCurriculumID,
  deleteCurriculumID,
  getCourseTypeCourse,
  getInfoMaintenance,
  getCourseTeacherName,
  getCourseContents,
  getAllCourseFilter,
  addCourseFilter
} from 'Apis/student'

export default function*  watcher() {
  yield all([
    takeLatest(TYPES.GET_STUDENT_INFORMATION, sagaHelper({ api: getStudentInformation })),
    takeLatest(TYPES.GET_SUBJECT_CONTENT, sagaHelper({ api: getSubjectContent })),
    takeLatest(TYPES.GET_MAX_SCORE, sagaHelper({ api: getMaxScore })),
    takeLatest(TYPES.GET_SCHEDULE_COURSE, sagaHelper({ api: getScheduleCourse})),
    takeLatest(TYPES.EXPORT_SCHEDULE_COURSE, sagaHelper({ api: exportScheduleCourse})),
    takeLatest(TYPES.GET_COMPULSORY_SUBJECTS, sagaHelper({ api: getCompulsorySubjects})),
    takeLatest(TYPES.GET_COMPULSORY_SUBJECTS_V2, sagaHelper({ api: getCompulsorySubjectsV2})),
    takeLatest(TYPES.GET_COURSE_FILTER, sagaHelper({ api: getAllCourseName })),
    takeLatest(TYPES.GET_ALL_COURSE_FILTER, sagaHelper({ api: getAllCourseFilter })),
    takeLatest(TYPES.ADD_COURSE_FILTER, sagaHelper({ api: addCourseFilter })),
    takeLatest(TYPES.SEARCH_COURSE_NAME, sagaHelper({ api: searchCourseName})),
    takeLatest(TYPES.GET_COURSE_BY_NAME, sagaHelper({ api: getCourseByName})),
    takeLatest(TYPES.ADD_NEW_CLASS_SCHEDULE, sagaHelper({ api: addNewClassSchedule})),
    takeLatest(TYPES.GET_COURSE_TYPE_NAME, sagaHelper({ api: getCourseTypeName})),
    takeLatest(TYPES.GET_COURSE_SUBJECTS, sagaHelper({ api: getCourseSubjects })),
    takeLatest(TYPES.GET_COURSE_TYPE_COURSE, sagaHelper({ api: getCourseTypeCourse })),
    takeLatest(TYPES.GET_COURSE_TEACHER_NAME, sagaHelper({ api: getCourseTeacherName })),
    takeLatest(TYPES.GET_COURSE_CONTENTS, sagaHelper({ api: getCourseContents })),
    takeLatest(TYPES.GET_INFOR_STUDENT_SAVED, sagaHelper({ api: getInforStudentSaved})),
    takeLatest(TYPES.GET_LIST_UNIVERSITY, sagaHelper({ api: getListUniversity})),
    takeLatest(TYPES.GET_ASPIRATIONAL_ID, sagaHelper({ api: getAspirationalId})),
    takeLatest(TYPES.GET_FULL_UNIVERSITY_INFORMATION, sagaHelper({ api: getFullUniversityInformation})),
    takeLatest(TYPES.GET_INFORMATION_PRINT, sagaHelper({ api: getInformationPrint})),
    takeLatest(TYPES.GET_LIST_CURRICULUM, sagaHelper({ api: getListCurriculum})),
    takeLatest(TYPES.GET_CURRICULUM_DETAIL, sagaHelper({ api: getCurriculumDetail})),
    takeLatest(TYPES.ADD_AI_CURRICULUM, sagaHelper({ api: addAiCurriculum })),
    takeLatest(TYPES.ACTIVE_AI_CURRICULUM, sagaHelper({ api: activeCurriculumID })),
    takeLatest(TYPES.DELETE_AI_CURRICULUM, sagaHelper({ api: deleteCurriculumID})),
    takeLatest(TYPES.UPDATE_CALENDER, sagaHelper({ api: updateCalender })),
    takeLatest(TYPES.GET_INFO_MAINTENANCE, sagaHelper({ api: getInfoMaintenance })),
    
    
  ])
}
