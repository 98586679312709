/* eslint-disable  array-callback-return */
import { TYPES } from "Store/Actions";
import _ from "lodash";
import { addKeyForListItem, getItemDifference, moveItemInArr } from "Utils/array";
import { ALL, SUBJECT_ORDER, listSubject, listTypeCourse, listTypeName } from "Utils/data-default";
import { transformMaxScoreValue } from "Utils/object-transform";
import dayjs from "dayjs";
import { convertAutotickSubject, convertValueSubjectContent, sortBySubjectOrder } from "Utils/convertValue";

const INIT_STATE = {
  loading: false,
  loadingCourseFilter: false,
  loadingScheduleCourse: false,
  isAddCourseSuccess: true,
  studentIDSaved: false,
  loadingstudentIDSaved: false,
  testLoading: true,
  //state information student
  studentID: "",
  listSubjectContentCommon: [],
  listSubjectContentPersonal: [],
  maxScore: {},
  inforStudent: {},
  listScheduleMethod: [],
  compulsorySubjects: {},
  scheduleCourse: [],
  currentStudentInformation: {},
  informationPrint: {},
  generatedClassScheduleID: "",
  
  typeName: [],
  subject: [],
  typeCourse: [],
  courseFilter: [],
  teacherName: [],
  courseContent: [],

  contentSubjectIDSaved: [],
  teacherComment: "",
  isDelete: false,
  aspirationalID: "",
  version: 1,

  // state aspirational
  listUniversity: [],
  dataUniversitys:{},

  // state curriculum
  listCurriculum: [],
  pageListCurriculum: 1,
  totalListCurriculum: 0,
  info_maintenance_student: {},
  open_modal_result: false,
};

const reducerStudent = (state = INIT_STATE, action) => {
  let newScheduleCourse;
  let updateScheduleCourse;
  const { data,payload } = action;
  switch (action.type) {
    case TYPES.GET_STUDENT_INFORMATION_REQUEST:
      return {
        ...state,
        loading: true,
        studentID: "",
        // maxScore: {},
        inforStudent: {},
        listScheduleMethod: [],
        // currentStudentInformation: {},
        informationPrint: {},
        // scheduleCourse: [],
        // compulsorySubjects: [],
        generatedClassScheduleID: "",
        studentIDSaved: false,
        contentSubjectIDSaved: [],
        isAddCourseSuccess: true,
        teacherComment:"",
        aspirationalID: "",
      };
    case TYPES.GET_SUBJECT_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_INFO_MAINTENANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_SCHEDULE_COURSE_REQUEST:
      return {
        ...state,
        loadingScheduleCourse: true,
      };
    case TYPES.GET_MAX_SCORE_REQUEST:
    case TYPES.EXPORT_SCHEDULE_COURSE_REQUEST:
    case TYPES.GET_COMPULSORY_SUBJECTS_REQUEST:
    case TYPES.GET_COMPULSORY_SUBJECTS_V2_REQUEST:
    case TYPES.GET_ASPIRATIONAL_ID_REQUEST:
    case TYPES.GET_FULL_UNIVERSITY_INFORMATION_REQUEST:
    case TYPES.GET_COURSE_BY_NAME_REQUEST:
    case TYPES.ADD_NEW_CLASS_SCHEDULE_REQUEST:
    case TYPES.ACTIVE_AI_CURRICULUM_REQUEST:
    case TYPES.DELETE_AI_CURRICULUM_REQUEST:
    case TYPES.GET_COURSE_TYPE_NAMEE_REQUEST:
    case TYPES.GET_COURSE_SUBJECTS_REQUEST:
    case TYPES.GET_COURSE_TYPE_COURSE_REQUEST:
    case TYPES.GET_COURSE_TEACHER_NAME_REQUEST:
    case TYPES.GET_COURSE_CONTENTS_REQUEST:
    case TYPES.UPDATE_CALENDER_REQUEST:
    case TYPES.GET_LIST_CURRICULUM_REQUEST:
      return {
        ...state,
      };
    case TYPES.ADD_AI_CURRICULUM_REQUEST:
      return {
        ...state,
        isAddCourseSuccess: true,
      };
    case TYPES.GET_COURSE_FILTER_REQUEST:
    case TYPES.GET_ALL_COURSE_FILTER_REQUEST:
      return {
        ...state,
        loadingCourseFilter: true,
      };
    case TYPES.ADD_COURSE_FILTER_REQUEST:
      return {
        ...state,
      };
    case TYPES.SEARCH_COURSE_NAME_REQUEST:
      return {
        ...state,
        loadingCourseName: true,
      };
    case TYPES.GET_INFOR_STUDENT_SAVED_REQUEST:
    case TYPES.GET_CURRICULUM_DETAIL_REQUEST:
      return {
        ...state,
        studentIDSaved:true,
        loadingstudentIDSaved:true,
      };
    case TYPES.GET_LIST_UNIVERSITY_REQUEST:
      return {
        ...state,
        listUniversity: []
      };
    case TYPES.GET_INFORMATION_PRINT_REQUEST:
      return {
        ...state,
        informationPrint: {},
      };
    case TYPES.CONFIRMED_INFORMATION:
      return {
        ...state,
        currentStudentInformation: action.data,
      };
    case TYPES.RESET_INFOMATION:
      const updatedStudentInformation = Object.assign({}, state.currentStudentInformation, action.data);
        return {
          ...state,
          currentStudentInformation: updatedStudentInformation,
        };
    case TYPES.RESET_ALL_INFOMATION:
        return {
          ...state,
          currentStudentInformation: {},
        };
    case TYPES.RESET_CURRICULUM:
      return {
        ...state,
        totalListCurriculum: 0,
        listCurriculum: [],
      };
    case TYPES.LIST_DATA_UNIVERSITY:
      return {
        ...state,
        dataUniversitys: action.data?.dataUniversitys
    };
    case TYPES.BACK_AUTHEN:
      return {
        ...state,
        testLoading: false,
      };
    case TYPES.ADD_COURSE:
      const nameSubject = state.subject.map(item => item?.value)
      newScheduleCourse = state.scheduleCourse
      if (data.location === "TOP") {
        nameSubject.map(subject => {
          const index = newScheduleCourse.findIndex(item => item['科目'] === subject);
          const dataCourseUpdate = data.courseChecked.filter(items => items['科目'] === subject)
          dataCourseUpdate.forEach((item) => {
            item.newCourse = "new";
          });
          const updatedArray = newScheduleCourse.slice(); 
          if (index < 0) {
            newScheduleCourse = dataCourseUpdate.concat(updatedArray)
          }
          else {
            updatedArray.splice(index, 0, ...dataCourseUpdate);
            newScheduleCourse = updatedArray; 
          }
        });
      }
      if (data.location === "BOTTOM") {
        nameSubject.map(subject => {
          const maxIndex = newScheduleCourse.reduce((maxIndex, currentObj, currentIndex) => {
              if (currentObj['科目'] === subject && currentIndex > maxIndex) {
                return currentIndex;
              }
              return maxIndex;
            }, -1);
          const dataCourseUpdate = data.courseChecked.filter(items => items['科目'] === subject)
          dataCourseUpdate.forEach((item) => {
            item.newCourse = "new";
          });
          const updatedArray = newScheduleCourse.slice(); 
          if (maxIndex < 0) {
            newScheduleCourse = updatedArray.concat(dataCourseUpdate)
          }
          else {
            updatedArray.splice(maxIndex + 1, 0, ...dataCourseUpdate);
            newScheduleCourse = updatedArray; 
          }
        });
      }
      return {
        ...state,
        scheduleCourse: newScheduleCourse,
        isDelete: true
      };
    case TYPES.UPDATE_MOVE_SUBJECT:
      updateScheduleCourse = _.cloneDeep(data.data)
        return {
          ...state,
          scheduleCourse: updateScheduleCourse,
        };
    case TYPES.UPDATE_SORT_SUBJECT:
      updateScheduleCourse = _.cloneDeep(data.data)
      return {
        ...state,
        scheduleCourse: updateScheduleCourse,
        currentStudentInformation: {
          ...state.currentStudentInformation,
          sortSubject: true,
        },
      };
    case TYPES.EDIT_COURSE:
      newScheduleCourse = _.cloneDeep(state.scheduleCourse)
      const index = _.findIndex(newScheduleCourse, { key: data.newDataRow.key});
      newScheduleCourse.splice(index, 1, data.newDataRow);
      return {
        ...state,
        scheduleCourse: newScheduleCourse,
        // isDelete: true
      };
    case TYPES.EDIT_ALL_COURSE:
      return {
        ...state,
        scheduleCourse: data.cloneListSchedule,
      };
    case TYPES.EDIT_CLH:
      newScheduleCourse = _.cloneDeep(state.scheduleCourse)
      if (data.optionEditCLH === 'one') {
        const indexCourse = _.findIndex(newScheduleCourse, { key: data.newData.key});
        newScheduleCourse.splice(indexCourse, 1, data.newData);
      }
      else {
        newScheduleCourse = data.newData
      }
      return {
        ...state,
        scheduleCourse: newScheduleCourse,
      };
    case TYPES.DELETED_COURSE:
      newScheduleCourse = _.differenceWith(state.scheduleCourse, data, _.isEqual);
      // const index = newScheduleCourse.findIndex(
      //   (element) => element["番号"] === data.item[`番号`]
      // );
      // newScheduleCourse.splice(index, 1);
      // if (typeof(index) === 'number') {
      //   return {
      //     ...state,
      //     scheduleCourse: newScheduleCourse,
      //     isDelete: true
      //   };
      // } else {
        return {
          ...state,
          scheduleCourse: newScheduleCourse,
          isDelete: false
        };
      // }
    case TYPES.MOVE_COURSE:
      newScheduleCourse = _.cloneDeep(state.scheduleCourse);
      const arrMoved = moveItemInArr(
        newScheduleCourse,
        data.fromIndex,
        data.toIndex
      );
      return {
        ...state,
        scheduleCourse: arrMoved,
        isDelete: true
      };
    case TYPES.GET_STUDENT_INFORMATION_SUCCESS:
      let arrMethod = [];
      if (data.data.scheduleMethod && data.data.scheduleMethod.length) {
        data.data.scheduleMethod.map((it) => {
          arrMethod.push({
            value: it,
            label: it,
          });
        });
      }

      let stateWhenSuccess = !data.data.generatedClassScheduleID
        ? {
            studentID: data.data.studentID,
            listScheduleMethod: arrMethod,
            inforStudent: data.data,
            loading: false,
          }
        : {
          studentID: data.data.studentID,
          inforStudent: data.data
        };
      return {
        ...state,
        ...stateWhenSuccess,
        testLoading: false
      };
    case TYPES.GET_SUBJECT_CONTENT_SUCCESS:
      // let arrSubjectContentCommon = [];
      // let arrSubjectContentPersonal = [];
      // for (const [key, value] of Object.entries(data.data["共通テスト"])) {
      //   arrSubjectContentCommon.push({
      //     name: key,
      //     child: value,
      //   });
      // }
      // for (const [key, value] of Object.entries(data.data["二次・個別"])) {
      //   arrSubjectContentPersonal.push({
      //     name: key,
      //     child: value,
      //   });
      // }
      let arrSubjectContentCommon = convertValueSubjectContent(data.data["共通テスト"]); 
      let arrSubjectContentPersonal = convertValueSubjectContent(data.data["二次・個別"]); 
      return {
        ...state,
        loading: false,
        listSubjectContentCommonFirst: data.data["共通テスト"],
        listSubjectContentPersonalFirst: data.data["二次・個別"],
        listSubjectContentCommon: arrSubjectContentCommon,
        listSubjectContentPersonal: arrSubjectContentPersonal,
      };
    case TYPES.GET_INFO_MAINTENANCE_SUCCESS:
      return {
        ...state,
        info_maintenance_student: {
          ...data.data,
        },
      };
    case TYPES.GET_MAX_SCORE_SUCCESS:
      
      return {
        ...state,
        maxScore: transformMaxScoreValue(data.data),
      };
    case TYPES.GET_SCHEDULE_COURSE_SUCCESS:
      return {
        ...state,
        scheduleCourse: addKeyForListItem(data.data.course),
        loadingScheduleCourse: false,
        aspirationalID: data.data.aspirational,
        version: data.data.version
      };
    case TYPES.EXPORT_SCHEDULE_COURSE_SUCCESS:
      return {
        ...state,
      };
    case TYPES.GET_COMPULSORY_SUBJECTS_SUCCESS:
      return {
        ...state,
        compulsorySubjects: data.data
      };
    case TYPES.GET_COMPULSORY_SUBJECTS_V2_SUCCESS:
      return {
        ...state,
        compulsorySubjects: {
          general_exam: convertAutotickSubject(data.data.general_exam),
          specific_exam : convertAutotickSubject(data.data.specific_exam)
        }
      };
    case TYPES.GET_COURSE_FILTER_SUCCESS:
    case TYPES.GET_ALL_COURSE_FILTER_SUCCESS:
      let arrCourseFilter = addKeyForListItem(data.data);
      let concatArrCourseFiler = payload.offset === 0 ? arrCourseFilter : state.courseFilter.concat(arrCourseFilter)
      
      let courseIsFiltered = getItemDifference(concatArrCourseFiler, state.scheduleCourse)

      return {
        ...state,
        courseFilter: sortBySubjectOrder(courseIsFiltered, SUBJECT_ORDER, '科目'),
        loadingCourseFilter: false,
      };
    case TYPES.ADD_COURSE_FILTER_SUCCESS:
      return {
        ...state,
      };
    case TYPES.CLEAR_COURSE_FILTER: 
    return {
      ...state,
      courseFilter: [],
    };
    case TYPES.SEARCH_COURSE_NAME_SUCCESS:
      let arrSearchCourseName = [];
      if (data.data && data.data.length) {
        data.data.map((it) => {
          arrSearchCourseName.push({
            value: it,
            label: it,
          });
        });
      }
      return {
        ...state,
        courseName: arrSearchCourseName,
        loadingCourseName: false,
      };
    case TYPES.GET_COURSE_BY_NAME_SUCCESS:
      return {
        ...state,
      };
    case TYPES.ADD_NEW_CLASS_SCHEDULE_SUCCESS:
      return {
        ...state,
        isAddCourseSuccess: false,
        generatedClassScheduleID: data.data.generatedClassScheduleID,
      };
    case TYPES.ADD_AI_CURRICULUM_SUCCESS:
      return {
        ...state,
        isAddCourseSuccess: false,
        generatedClassScheduleID: data.data.curriculumID,
        teacherComment : data.data.teacherComment 
      };
    case TYPES.CHANGE_TEACHER_COMMENT:
      return {
          ...state,
          teacherComment : data.teacherComment
      };
    case TYPES.ACTIVE_AI_CURRICULUM_SUCCESS:
        const updatedArrayActive = state.listCurriculum.slice(); 
        const result = updatedArrayActive.map(item => {
          if (item?.curriculumID === data.data) {
              return {...item, is_activate : 1}
          }
          else {
            return {...item, is_activate : 0}
          }
        })
      // const updatedItem = result.find(item => item?.is_activate === 1);
      //   result.splice(result.indexOf(updatedItem), 1);
      //   result.unshift(updatedItem);
        return {
          ...state,
          listCurriculum: result,
        };
    case TYPES.DELETE_AI_CURRICULUM_SUCCESS:
        return {
          ...state,
      };
    case TYPES.UPDATE_DELETE_AI_CURRICULUM:
      let dataAray = state.listCurriculum.slice();
      let resultDelete = dataAray.filter(item => item.curriculumID !== data.curriculumID)
        return {
          ...state,
          listCurriculum: resultDelete,
          totalListCurriculum : state.totalListCurriculum-1
      };
    case TYPES.GET_COURSE_TYPE_NAME_SUCCESS:
      let arrTypeName = [];
      const object_typeName = {};
      for (let i = 0; i < listTypeName.length; i++) {
        object_typeName[listTypeName[i]] = i;
      }
      const arrTypename = data.data.sort(function(a, b) {
        return object_typeName[a] - object_typeName[b];
      });
      arrTypename.unshift(ALL);
      if (arrTypename && arrTypename.length) {
        arrTypename.map((it) => {
          arrTypeName.push({
            value: it === ALL ? '' : it,
            label: it,
            item: "method",
          });
        });
      }
      return {
        ...state,
        typeName: arrTypeName,
      };
    case TYPES.GET_COURSE_SUBJECTS_SUCCESS:
      let arrSubject = [];
      const object_subject = {};
      for (let y = 0; y < listSubject.length; y++) {
        object_subject[listSubject[y]] = y;
      }
      const arrData = data.data.sort(function(a, b) {
        return object_subject[a] - object_subject[b];
      });
      arrData.unshift(ALL);
      if (arrData && arrData.length) {
        arrData.map((it) => {
          arrSubject.push({
            value: it === ALL ? '' : it,
            label: it,
            item: "subject",
          });
        });
      }
      return {
        ...state,
        subject: arrSubject,
      };
      case TYPES.GET_COURSE_TYPE_COURSE_SUCCESS:
        let arrTypeCourse = [];
        const object_typeCourse = {};
        for (let i = 0; i < listTypeCourse.length; i++) {
          object_typeCourse[listTypeCourse[i]] = i;
        }
        const dataFilter = data.data.filter(item => item !== '添削')
      
        const arrTypecourse = dataFilter.sort(function(a, b) {
          return object_typeCourse[a] - object_typeCourse[b];
        });
        arrTypecourse.unshift(ALL);
        if (arrTypecourse && arrTypecourse.length) {
          arrTypecourse.map((it) => {
            arrTypeCourse.push({
              value: it === ALL ? '' : it,
              label: it,
              item: "type_course",
            });
          });
        }
        return {
          ...state,
          typeCourse: arrTypeCourse,
        };
    case TYPES.GET_COURSE_TEACHER_NAME_SUCCESS:
      let arrCourseTeacher = [];
      const dataTeacherName = data.data
      dataTeacherName.unshift(ALL);
      if (data.data && data.data.length) {
        data.data.map((it) => {
          if (it) {
            arrCourseTeacher.push({
              value: it === ALL ? '' : it,
              label: it,
              item: "teacher_name",
            });
          }
        });
      }
      return {
        ...state,
        teacherName: arrCourseTeacher,
      };
    case TYPES.GET_COURSE_CONTENTS_SUCCESS:
        let arrCourseContents = [];
      const dataContents = data.data
        dataContents.unshift(ALL);
        if (data.data && data.data.length) {
          data.data.map((it) => {
            arrCourseContents.push({
              value: it === ALL ? '' : it,
              label: it,
              item: "content",
            });
          });
        }
        return {
          ...state,
          courseContent: arrCourseContents,
        };

    
    case TYPES.GET_INFOR_STUDENT_SAVED_SUCCESS:
        return {
        ...state,
        studentID: data.data.studentID,
        scheduleCourse: addKeyForListItem(data.data.course),
        // maxScore: transformMaxScoreValue(data.data.score)
        aspirationalID: data.data.aspirational.aspirationalID,
        listScheduleMethod: [{
          value: data.data.aspirational.scheduleMethod,
          label: data.data.aspirational.scheduleMethod,
        }],
        contentSubjectIDSaved: data.data.selectedBox,
        currentStudentInformation: {
          studentID: data.data.studentID,
          selectedContent: data.data.selectedBox,
          startTime: dayjs(data.data.startTime).format("YYYY/MM/DD"),
          endTime: dayjs(data.data.endTime).format("YYYY/MM/DD"),
          scheduleMethod: data.data.aspirational.scheduleMethod,
          personality: data.data.personality,
          socialScience: data.data.scienceType,
          version: data.data.version,
          ...data.data.aspirational
        },
        loadingstudentIDSaved: false,
        teacherComment: data.data.teacherComment || "",
        isDelete: false,
      };
    case TYPES.GET_LIST_UNIVERSITY_SUCCESS:
      const listUniversity = data.data.university_names.map((str, index) => ({ value: str, label: str }));
      return {
        ...state,
        listUniversity: listUniversity
      };

    case TYPES.GET_LIST_CURRICULUM_SUCCESS:
      const test = data.items.map((curri) => {
        return {
          studentID: curri.studentID,
          selectedContent: curri.selectedBox,
          startTime: dayjs(curri.startTime).format("YYYY/MM/DD"),
          endTime: dayjs(curri.endTime).format("YYYY/MM/DD"),
          scheduleMethod: curri.aspirational.scheduleMethod,
          universityName: curri.aspirational.universityName,
          departmentName: curri.aspirational.departmentName,
          majorName: curri.aspirational.majorName,
          branchAspiration: curri.aspirational.branchAspiration,
          personality: curri.personality,
          socialScience: curri.scienceType,
          version: curri.version,
          ...curri.personality,
          ...curri
        }
      })
      let  dataList
      if (state.totalListCurriculum === 0 || state.listCurriculum.length === 0) {
        dataList = test
      }
      else { 
        dataList = [...state.listCurriculum, ...test]
        
      }
      return {
        ...state,
        listCurriculum: dataList,
        totalListCurriculum: data.total
      }
    case TYPES.GET_CURRICULUM_DETAIL_SUCCESS:
      return {
        ...state,
        studentID: data.data.studentID,
        scheduleCourse: addKeyForListItem(data.data.course),
        // maxScore: transformMaxScoreValue(data.data.score)
        aspirationalID: data.data.aspirational.aspirationalID,
        listScheduleMethod: [{
          value: data.data.aspirational.scheduleMethod,
          label: data.data.aspirational.scheduleMethod,
        }],
        // contentSubjectIDSaved: data.data.selectedBox,
        contentSubjectIDSaved: Array.isArray(data.data.selectedBox['二次・個別']['英語']) ? data.data.selectedBox : {
          '共通テスト': convertAutotickSubject(data.data.selectedBox['共通テスト']),
          '二次・個別': convertAutotickSubject(data.data.selectedBox['二次・個別'])
        },

        currentStudentInformation: {
          studentID: data.data.studentID,
          selectedContent: data.data.selectedBox,
          startTime: dayjs(data.data.startTime).format("YYYY/MM/DD"),
          endTime: dayjs(data.data.endTime).format("YYYY/MM/DD"),
          scheduleMethod: data.data.aspirational.scheduleMethod,
          personality: data.data.personality,
          socialScience: data.data.scienceType,
          version: data.data.version,
          ...data.data.aspirational
        },
        // curriculumID: data.data.curriculumID,
        loadingstudentIDSaved: false,
        teacherComment: data.data.teacherComment || "",
        isDelete: false,
        generatedClassScheduleID: data.data.curriculumID,
      };
    case TYPES.GET_FULL_UNIVERSITY_INFORMATION_SUCCESS:
      return {
        ...state,
      };
    case TYPES.GET_ASPIRATIONAL_ID_SUCCESS:
      return {
        ...state,
        aspirationalID: data.data
      };
    case TYPES.GET_INFORMATION_PRINT_SUCCESS:
      return {
        ...state,
        informationPrint: data.data,
      };
    case TYPES.UPDATE_CALENDER_SUCCESS:
        return {
          ...state,
          scheduleCourse: addKeyForListItem(data.data.course)
        };
    case TYPES.UPDATE_SCHEDULE_COURSE: 
      return {
        ...state,
        scheduleCourse: data.scheduleCourse
      }
    case TYPES.UPDATE_STATE_MODAL: 
      return {
        ...state,
        open_modal_result: data.stateModal
      }
    case TYPES.GET_INFOR_STUDENT_SAVED_FAILURE:
      return {
        ...state,
        loadingstudentIDSaved: false
      };
    case TYPES.GET_SCHEDULE_COURSE_FAILURE:
      return {
        ...state,
        loadingScheduleCourse: false,
      };
    case TYPES.GET_SUBJECT_CONTENT_FAILURE:
      return {
        ...state,
        loading: false,
        listSubjectContentCommon: [],
        listSubjectContentPersonal: [],
        testLoading: true
      };
    case TYPES.GET_STUDENT_INFORMATION_FAILURE:
      return {
        ...state,
        loading: false,
        testLoading: true,
      };
    case TYPES.GET_COURSE_FILTER_FAILURE:
    case TYPES.GET_ALL_COURSE_FILTER_FAILURE: 
      return {
        ...state,
        loadingCourseFilter: false,
      };
    case TYPES.SEARCH_COURSE_NAME_FAILURE:
      return {
        ...state,
        loadingCourseName: false,
      };
    case TYPES.GET_MAX_SCORE_FAILURE:
    case TYPES.EXPORT_SCHEDULE_COURSE_FAILURE:
    case TYPES.GET_COMPULSORY_SUBJECTS_FAILURE:
    case TYPES.GET_COMPULSORY_SUBJECTS_V2_FAILURE:
    case TYPES.GET_COURSE_BY_NAME_FAILURE:
    case TYPES.ADD_NEW_CLASS_SCHEDULE_FAILURE:
    case TYPES.ADD_AI_CURRICULUM_FAILURE:
    case TYPES.ACTIVE_AI_CURRICULUM_FAILURE:
    case TYPES.DELETE_AI_CURRICULUM_FAILURE:
    case TYPES.GET_COURSE_TYPE_NAME_FAILURE:
    case TYPES.GET_COURSE_TYPE_COURSE_FAILURE:
    case TYPES.GET_COURSE_SUBJECTS_FAILURE:
    case TYPES.GET_COURSE_TEACHER_NAME_FAILURE:
    case TYPES.GET_LIST_UNIVERSITY_FAILURE:
    case TYPES.GET_FULL_UNIVERSITY_INFORMATION_FAILURE:
    case TYPES.GET_ASPIRATIONAL_ID_FAILURE:
    case TYPES.GET_INFORMATION_PRINT_FAILURE:
    case TYPES.UPDATE_CALENDER_FAILURE:
    case TYPES.GET_LIST_CURRICULUM_FAILURE:
    case TYPES.GET_INFO_MAINTENANCE_FAILURE:
    case TYPES.ADD_COURSE_FILTER_FAILURE:
      return {
        ...state,
      }
    case TYPES.GET_CURRICULUM_DETAIL_FAILURE:
        return {
          ...state,
          loadingstudentIDSaved: false
        }
      
    default:
      return state;
  }
};

export default reducerStudent;
