/* eslint-disable  array-callback-return */
import _ from "lodash"

export function moveItemInArr(arr, old_index, new_index) {
  if (new_index >= arr.length) {
      let k = new_index - arr.length + 1;
      while (k--) {
          arr.push(undefined);
      }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr; // for testing
};

export function getItemDifference(Array1, Array2) {
  let newArray = _.differenceWith(Array1, Array2, (arrVal, othVal) => arrVal[`番号`] === othVal[`番号`])
  return newArray; // for testing
};

export const addKeyForListItem = (list) => {
  let newList = [];
  if (list && list.length) {
    list.map((it) => {
      newList.push({
        ...it,
        key: it[`番号`],
      });
    });
  }
  return newList;
};