import {combineReducers} from 'redux'
import {connectRouter} from 'connected-react-router'
import Student from './student'
import Admin from './admin'

const rootReducer = (history) => combineReducers({
  router: connectRouter(history),
  student: Student,
  admin: Admin,
})

export default rootReducer
