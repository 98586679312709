const TYPES = [
  // API

  /** Auth */
  'LOGIN',
  'SHOW_MESSAGE',
  'SET_INIT_URL',
  'USER_SIGN_OUT',
  'INIT_URL',
  'USER_TOKEN_SET',
  'USER_DATA',
  'TOKEN_EXPIRED',
  'INVALID_TOKEN',
  'UPDATE_INFORMATION',
  'UPDATE_PASSWORD',
  'BACK_AUTHEN',

  /** Student */
  'GET_STUDENT_INFORMATION',
  'GET_SUBJECT_CONTENT',
  'GET_MAX_SCORE',
  'GET_SCHEDULE_COURSE',
  'EXPORT_SCHEDULE_COURSE',
  'GET_COMPULSORY_SUBJECTS',
  'GET_COMPULSORY_SUBJECTS_V2',
  'GET_INFO_MAINTENANCE',
  'UPDATE_INFO_MAINTENANCE',

  // Curriculum
  'GET_LIST_CURRICULUM',
  'GET_CURRICULUM_DETAIL',
  'ADD_AI_CURRICULUM',
  'ACTIVE_AI_CURRICULUM',
  'DELETE_AI_CURRICULUM',
  'UPDATE_DELETE_AI_CURRICULUM',
  // schedule course
  'RESET_INFOMATION',
  "RESET_ALL_INFOMATION",
  "RESET_CURRICULUM",
  'LIST_DATA_UNIVERSITY',
  'CONFIRMED_INFORMATION',
  "GET_COURSE_FILTER",
  "GET_ALL_COURSE_FILTER",
  "ADD_COURSE_FILTER",
  "CLEAR_COURSE_FILTER",
  "SEARCH_COURSE_NAME",
  "ADD_COURSE",
  "DELETED_COURSE",
  "GET_COURSE_BY_NAME",
  "GET_COURSE_BY_NAME",
  "ADD_NEW_CLASS_SCHEDULE",
  "MOVE_COURSE",
  "GET_COURSE_TYPE_NAME",
  "GET_COURSE_SUBJECTS",
  "GET_COURSE_TYPE_COURSE",
  "GET_COURSE_TEACHER_NAME",
  "GET_COURSE_CONTENTS",
  "GET_INFOR_STUDENT_SAVED",
  "EDIT_COURSE",
  "EDIT_ALL_COURSE",
  "EDIT_CLH",
  "GET_INFORMATION_PRINT",
  "UPDATE_CALENDER",
  "UPDATE_MOVE_SUBJECT",
  "UPDATE_SORT_SUBJECT",
  "CHANGE_TEACHER_COMMENT",
  "UPDATE_SCHEDULE_COURSE",
  "UPDATE_STATE_MODAL",
  // Aspirational
  "GET_LIST_UNIVERSITY",
  "GET_ASPIRATIONAL_ID",
  "GET_FULL_UNIVERSITY_INFORMATION",


  //ADMIN
  "IMPORT_LIST_COURSE",
  "IMPORT_COURSE_DEPENDENCIES",
  "IMPORT_SEIHOKU",
  "IMPORT_SCHOOL_GROUP",
  "GET_SERIES_ADMIN",
  "GET_SUBJECT",
  "GET_CLASSIFICATION",
  "GET_CONTENT",
  "GET_EDITED",
  "GET_EXAM",
  "GET_VERSION",
  "GET_METHOD",
  "GET_GROUP_SCHOOL",
  "SET_SELECT_GROUP",
  "GET_UNIVERSITY_ID",
  "GET_VERSION_IMPORT",
  "GET_VERSION_FILTER",
  "GET_LIST_STUDENT_ID",
  "GET_LIST_STUDENT_NAME",
  "GET_LIST_UNIVERSITY_ADMIN",
  "GET_LIST_FULL_UNIVERSITY_INFOMATION",
  "RESET_STATE",
  'GET_INFO_MAINTENANCE_ADMIN',
]

export default TYPES
