import {all} from 'redux-saga/effects'
import Student from './student'
import  Admin from './admin'

export default function* rootSaga(getState) {
  yield all([
    Student(),
    Admin()
  ])
}
