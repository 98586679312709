import { getClassification, getContent, getEdited, getExam, getImportCoursedependenciesAdmin, getImportListCourseAdmin, getImportSchoolGroup, getImportSeihoku, getInfoMaintenanceAdmin, getMethod, getSchoolGroup, getSeries, getSubject, getUniversityId, getVersion, listStudentId, listStudentName, listUniversityAdmin, listUniversityFullInfomation } from 'Apis/admin'
import { all, takeLatest } from 'redux-saga/effects'
import { TYPES } from 'Store/Actions'
import sagaHelper from 'Utils/saga-helper'


export default function* watcher() {
  yield all([
    takeLatest(TYPES.IMPORT_LIST_COURSE, sagaHelper({ api: getImportListCourseAdmin })),
    takeLatest(TYPES.IMPORT_COURSE_DEPENDENCIES, sagaHelper({ api: getImportCoursedependenciesAdmin })),
    takeLatest(TYPES.IMPORT_SEIHOKU, sagaHelper({ api: getImportSeihoku })),
    takeLatest(TYPES.IMPORT_SCHOOL_GROUP, sagaHelper({ api: getImportSchoolGroup })),
    takeLatest(TYPES.GET_SERIES_ADMIN, sagaHelper({ api: getSeries })),
    takeLatest(TYPES.GET_SUBJECT, sagaHelper({ api: getSubject })),
    takeLatest(TYPES.GET_CLASSIFICATION, sagaHelper({ api: getClassification })),
    takeLatest(TYPES.GET_CONTENT, sagaHelper({ api: getContent })),
    takeLatest(TYPES.GET_EDITED, sagaHelper({ api: getEdited })),
    takeLatest(TYPES.GET_EXAM, sagaHelper({ api: getExam })),
    takeLatest(TYPES.GET_VERSION, sagaHelper({ api: getVersion })),
    takeLatest(TYPES.GET_METHOD, sagaHelper({ api: getMethod })),
    takeLatest(TYPES.GET_GROUP_SCHOOL, sagaHelper({ api: getSchoolGroup })),
    takeLatest(TYPES.GET_UNIVERSITY_ID, sagaHelper({ api: getUniversityId })),
    takeLatest(TYPES.GET_VERSION_IMPORT, sagaHelper({ api: getVersion })),
    takeLatest(TYPES.GET_VERSION_FILTER, sagaHelper({ api: getVersion })),
    takeLatest(TYPES.GET_LIST_STUDENT_ID, sagaHelper({ api: listStudentId })),
    takeLatest(TYPES.GET_LIST_STUDENT_NAME, sagaHelper({ api: listStudentName })),
    takeLatest(TYPES.GET_LIST_UNIVERSITY_ADMIN, sagaHelper({ api: listUniversityAdmin })),
    takeLatest(TYPES.GET_LIST_FULL_UNIVERSITY_INFOMATION, sagaHelper({ api: listUniversityFullInfomation })),
    takeLatest(TYPES.GET_INFO_MAINTENANCE_ADMIN, sagaHelper({ api: getInfoMaintenanceAdmin})),
  ])
}
