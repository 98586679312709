/* eslint-disable  array-callback-return */
import { TYPES } from "Store/Actions";

const INIT_STATE = {
  loading: false,
  loadingVersionImport: false,
  loadingVersionFilter: false,
  dataImportCourse: [],
  dataCourseDependencies: [],
  dataImportSeihoku: [],
  dataImportSchoolGroup: [],
  dataInvalidSchool: [],
  dataSeries: [],
  dataSubject: [],
  dataClassification: [],
  dataMethod: [],
  dataContent: [],
  dataEdited: [],
  dataExam: [],
  dataVersion: [],
  selectGroup: "",
  dataGroupSchool: [],
  dataUniversityID: [],
  dataVersionImport: [],
  dataVersionFilter: [],
  dataStudentId: [],
  dataStudentName: [],
  dataUniversityAdmin: [],
  dataFullUniverSityInfomation: [],
};

const reducerAdmin = (state = INIT_STATE, action) => {
  const { data } = action;
  switch (action.type) {
    case TYPES.IMPORT_LIST_COURSE_REQUEST:
    case TYPES.IMPORT_COURSE_DEPENDENCIES_REQUEST:
    case TYPES.IMPORT_SEIHOKU_REQUEST:
    case TYPES.IMPORT_SCHOOL_GROUP_REQUEST:
    case TYPES.GET_SERIES_ADMIN_REQUEST:
    case TYPES.GET_SUBJECT_REQUEST:
    case TYPES.GET_CLASSIFICATION_REQUEST:
    case TYPES.GET_METHOD_REQUEST:
    case TYPES.GET_CONTENT_REQUEST:
    case TYPES.GET_EDITED_REQUEST:
    case TYPES.GET_EXAM_REQUEST:
    case TYPES.GET_VERSION_REQUEST:
    case TYPES.GET_GROUP_SCHOOL_REQUEST:
    case TYPES.GET_UNIVERSITY_ID_REQUEST:
    case TYPES.GET_LIST_STUDENT_ID_REQUEST:
    case TYPES.GET_LIST_STUDENT_NAME_REQUEST:
    case TYPES.GET_LIST_UNIVERSITY_ADMIN_REQUEST:
    case TYPES.GET_LIST_FULL_UNIVERSITY_INFOMATION_REQUEST:
    case TYPES.GET_INFO_MAINTENANCE_ADMIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case TYPES.GET_VERSION_IMPORT_REQUEST:
      return {
        ...state,
        loadingVersionImport: true,
      };
    case TYPES.GET_VERSION_FILTER_REQUEST:
      return {
        ...state,
        loadingVersionFilter: true,
      };

    case TYPES.IMPORT_LIST_COURSE_SUCCESS:
      return {
        ...state,
        dataImportCourse: data.data,
        loading: false,
      };
    case TYPES.IMPORT_COURSE_DEPENDENCIES_SUCCESS:
      return {
        ...state,
        dataCourseDependencies: data.data,
        loading: false,
      };
    case TYPES.IMPORT_SEIHOKU_SUCCESS:
      return {
        ...state,
        dataImportSeihoku: data.data,
        loading: false,
      };
    case TYPES.IMPORT_SCHOOL_GROUP_SUCCESS:
      return {
        ...state,
        dataImportSchoolGroup: data.data,
        loading: false,
      };
    case TYPES.GET_SERIES_ADMIN_SUCCESS:
      let dataArrSeries = [];
      for (const [key, value] of Object.entries(data.data)) {
        dataArrSeries.push({
          value: key,
          label: value,
        });
      }
      return {
        ...state,
        dataSeries: dataArrSeries,
        loading: false,
      };
    case TYPES.GET_SUBJECT_SUCCESS:
      let dataArrSubject = [];
      for (const value of data.data) {
        dataArrSubject.push({
          value: value,
          label: value,
        });
      }
      return {
        ...state,
        dataSubject: dataArrSubject,
        loading: false,
      };
    case TYPES.GET_CLASSIFICATION_SUCCESS:
      let dataArrClassification = [];
      for (const [key, value] of Object.entries(data.data)) {
        dataArrClassification.push({
          value: key,
          label: value,
        });
      }
      return {
        ...state,
        dataClassification: dataArrClassification,
        loading: false,
      };
    case TYPES.GET_METHOD_SUCCESS:
      let dataArrMethod = [];
      data.data.map((value) => {
        dataArrMethod.push({
          value: value,
          label: value,
        });
      });
      return {
        ...state,
        dataMethod: dataArrMethod,
        loading: false,
      };
    case TYPES.GET_CONTENT_SUCCESS:
      let dataArrContent = [];
      data.data.map((value) => {
        dataArrContent.push({
          value: value,
          label: value,
        });
      });
      return {
        ...state,
        dataContent: dataArrContent,
        loading: false,
      };
    case TYPES.GET_EDITED_SUCCESS:
      let dataArrEdit = [];
      data.data.map((value) => {
        dataArrEdit.push({
          value: value,
          label: value,
        });
      });
      return {
        ...state,
        dataEdited: dataArrEdit,
        loading: false,
      };
    case TYPES.GET_EXAM_SUCCESS:
      let dataArrExam = [];
      for (const [key, value] of Object.entries(data.data)) {
        dataArrExam.push({
          value: key,
          label: value,
        });
      }
      return {
        ...state,
        dataExam: dataArrExam,
        loading: false,
      };
    case TYPES.GET_VERSION_SUCCESS:
      let dataArrVersion = [];

      for (const [key, value] of Object.entries(data.data)) {
        dataArrVersion.push({
          value: key,
          label: value ? `${key} - ${value}` : key,
        })
      }

      return {
        ...state,
        dataVersion: dataArrVersion,
        loading: false,
      };
    case TYPES.SET_SELECT_GROUP:
      return {
        ...state,
        selectGroup: data.value,
        loading: false,
      };
    case TYPES.GET_GROUP_SCHOOL_SUCCESS:
      let dataArrGroupSchool = [];
      for (const [key, value] of Object.entries(data.data)) {
        dataArrGroupSchool.push({
          value: key,
          label: value,
        });
      }

      return {
        ...state,
        selectGroup: dataArrGroupSchool?.[0]?.value,
        dataGroupSchool: dataArrGroupSchool,
        loading: false,
      };
    case TYPES.GET_UNIVERSITY_ID_SUCCESS:
      let dataArrUniversityId = [];
      data.data.map((value) => {
        dataArrUniversityId.push({
          value: value,
          label: value,
        });
      });

      return {
        ...state,
        dataUniversityID: dataArrUniversityId,
        loading: false,
      };
    case TYPES.GET_VERSION_IMPORT_SUCCESS:
      let dataArrVersionImport = [];
      for (const [key, value] of Object.entries(data.data)) {
        dataArrVersionImport.push({
          value: key,
          label: value ? `${key} - ${value}` : key,
        })
      }
      return {
        ...state,
        dataVersionImport: dataArrVersionImport,
        loadingVersionImport: false,
      };
    case TYPES.GET_VERSION_FILTER_SUCCESS:
      let dataArrVersionFilter = [];
      
      for (const [key, value] of Object.entries(data.data)) {
        dataArrVersionFilter.push({
          value: key,
          label: value ? `${key} - ${value}` : key,
        })
      }

      return {
        ...state,
        dataVersionFilter: dataArrVersionFilter,
        loadingVersionFilter: false,
      };
    case TYPES.GET_LIST_STUDENT_ID_SUCCESS:
      let dataArrStudentId = [];
      data.map((value) => {
        dataArrStudentId.push({
          value: value,
          label: value,
        });
      });
      return {
        ...state,
        dataStudentId: dataArrStudentId,
        loading: false,
      };
    case TYPES.GET_LIST_STUDENT_NAME_SUCCESS:
      let dataArrStudentName = [];
      data.map((value) => {
        dataArrStudentName.push({
          value: value,
          label: value,
        });
      });
      return {
        ...state,
        dataStudentName: dataArrStudentName,
        loading: false,
      };
    case TYPES.GET_LIST_UNIVERSITY_ADMIN_SUCCESS:
      let dataArrUniversity = [];
      data?.data?.university_names?.map((value) => {
        dataArrUniversity.push({
          value: value,
          label: value,
        });
      });
      return {
        ...state,
        dataUniversityAdmin: dataArrUniversity,
        loading: false,
      };
    case TYPES.GET_LIST_FULL_UNIVERSITY_INFOMATION_SUCCESS:
      return {
        ...state,
        dataFullUniverSityInfomation: data?.data,
        loading: false,
      };
    case TYPES.GET_INFO_MAINTENANCE_ADMIN_SUCCESS:
        return {
          ...state,
          info_maintenance: {
            ...data.data,
          },
      };
    case TYPES.UPDATE_INFO_MAINTENANCE:
        return {
          ...state,
          info_maintenance:data,
    };
    case TYPES.RESET_STATE:
      return INIT_STATE;
    case TYPES.IMPORT_LIST_COURSE_FAILURE:
    case TYPES.IMPORT_COURSE_DEPENDENCIES_FAILURE:
    case TYPES.IMPORT_SEIHOKU_FAILURE:
    case TYPES.IMPORT_SCHOOL_GROUP_FAILURE:
    case TYPES.GET_SERIES_ADMIN_FAILURE:
    case TYPES.GET_SUBJECT_FAILURE:
    case TYPES.GET_CLASSIFICATION_FAILURE:
    case TYPES.GET_METHOD_FAILURE:
    case TYPES.GET_CONTENT_FAILURE:
    case TYPES.GET_EDITED_FAILURE:
    case TYPES.GET_EXAM_FAILURE:
    case TYPES.GET_VERSION_FAILURE:
    case TYPES.GET_GROUP_SCHOOL_FAILURE:
    case TYPES.GET_UNIVERSITY_ID_FAILURE:
    case TYPES.GET_LIST_STUDENT_ID_FAILURE:
    case TYPES.GET_LIST_STUDENT_NAME_FAILURE:
    case TYPES.GET_LIST_UNIVERSITY_ADMIN_FAILURE:
    case TYPES.GET_LIST_FULL_UNIVERSITY_INFOMATION_FAILURE:
    case TYPES.GET_INFO_MAINTENANCE_ADMIN_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TYPES.GET_VERSION_IMPORT_FAILURE:
      return {
        ...state,
        loadingVersionImport: false,
      };
    case TYPES.GET_VERSION_FILTER_FAILURE:
      return {
        ...state,
        loadingVersionFilter: false,
      };

    default:
      return state;
  }
};

export default reducerAdmin;
