/* eslint-disable react-hooks/exhaustive-deps */
import { STORAGE, getLocalStorage } from "Utils/storage";
import { useHistory } from "react-router-dom";


const PrivateRoutes = ({ children }) => {
    const history = useHistory()
    const token = (getLocalStorage(STORAGE.ADMIN_TOKEN) || null)
    if (!token) {
        history.push({
            pathname: `/admin/login`,
        })
    }
    return children
};

export default PrivateRoutes;
